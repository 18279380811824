<template>
  <div>
    <div>
      <div class="fix-top" style="margin-bottom: 10px">
        <el-button
          @click="$router.push('/business/developer/agentDeveloperList')"
          icon="el-icon-arrow-left"
          type="primary"
          >返回</el-button
        >
      </div>
      <el-row :gutter="6">
        <el-col :span="4">
          <el-input placeholder="请输入客户名称" v-model="searchData.userName">
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-select
            style="width: 100%"
            clearable
            filterable
            v-model="searchData.contractStatus"
            placeholder="合同状态"
          >
            <el-option
              v-for="item in contractStatusList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch()"
            >查询</el-button
          >
        </el-col>
      </el-row>
      <PageTable
        rowKey="releaseId"
        :columns="columns"
        :tableData="tableData"
        @page-change="handleChangePage"
      >
        <template slot-scope="{ row }" slot="contractStatus">
          <el-tag :type="row.contractStatus | tagType(contractStatusList)">
            {{ row.contractStatus | typeDesc(contractStatusList) }}
          </el-tag>
        </template>
        <template slot-scope="{ row }" slot="contractStartTime">
          <span v-if="row.auditStatus == 6">{{ row.contractStartTime }}</span>
        </template>
        <template slot-scope="{ row }" slot="contractEndTime">
          <span v-if="row.auditStatus == 6">{{ row.contractEndTime }}</span>
        </template>
      </PageTable>
    </div>
  </div>
</template>
<script>
import {
  contractAuditList,
  contractTypeList,
  contractStatusList,
} from "@/common/constant/constant.js";
import {
  contractAudit,
  getContractList,
  checkContractInfo,
  addContractInfo,
} from "./indexServe";
import PageTable from "@/components/PageTableSort";
export default {
  name: "agentCustomerList",
  components: { PageTable },
  data() {
    return {
      contractAuditList,
      contractTypeList,
      contractStatusList,
      searchData: {
        date: [],
      },
      tableData: {
        list: [],
      },
      columns: [
        {
          key: "userName",
          label: "客户名称",
        },
        {
          slot: "contractStartTime",
          label: "合同开始时间",
        },
        {
          slot: "contractEndTime",
          label: "合同结束时间",
        },
        {
          slot: "contractStatus",
          label: "合同状态",
        },
        {
          key: "agentRemarks",
          label: "备注",
        },
      ],
      detailData: {},
      contractDetail: { invoice: {}, postInfo: {}, userInfo: {} },
      dialogAuditVisible: false,
      dialogDetailVisible: false,
      reviewList: [1, 2], // 可审核的状态列表
      serachKeyword: "",
    };
  },
  activated() {
    if (this.$route.query.agentId) {
      this.serachKeyword = this.$route.query.agentId;
    } else {
      this.serachKeyword = "";
    }
    this.init();
  },
  methods: {
    // 事件监听
    handleChangePage({ page, size }) {
      this.getTableList({ page, size });
    },

    handleSearch() {
      this.getTableList({ page: 1 });
    },
    handleViewDetail(row) {
      this.dialogDetailVisible = true;
      this.checkContractInfo({ id: row.id, contractType: row.contractType });
    },
    handleReview(row) {
      this.detailData = { ...row };
      this.dialogAuditVisible = true;
    },
    handleSubmitAudit(formData) {
      const { auditStatus, id } = this.detailData;
      // auditType审核类型：0签约审核，1签署审核 2运营审核
      const data = { id, auditType: 0, ...formData };
      if (auditStatus == 2) {
        data.auditType = 1; //签署审核
      }
      if (formData.submitType == 1) {
        this.contractAudit(data);
      } else if (formData.submitType == 2) {
        // 商务对接，签署合同时，先保存合同信息，再调用审核通过接口
        addContractInfo(data).then((res) => {
          if (res && res.code === 0) {
            this.contractAudit(data);
          }
        });
      }
    },
    // 后端请求
    // 获取列表
    async getTableList(params) {
      const { date, auditStatus, ...rest } = this.searchData;
      const _params = {
        page: 1,
        size: 10,
        auditStatus,
        ...rest,
        ...params,
      };
      if (_params.contractStatus == "") {
        delete _params.contractStatus;
      }
      if (this.serachKeyword) {
        _params.agentId = this.serachKeyword;
      }
      if (date) {
        _params.submitStart = date[0];
        _params.submitEnd = date[1];
      }
      if (!auditStatus && auditStatus !== 0) {
        _params.auditStatus = -1; // 没选择状态时，-1查询全部
      }
      _params.pageIndex = _params.page;
      _params.pageSize = _params.size;
      if (!_params.contractType) {
        _params.contractType = 0; //没写签约类型时给后端传0
      }
      const res = await getContractList(_params);
      if (res && res.code === 0) {
        const tableData = {
          list: res.data.contractList || [],
          page: _params.page,
          size: _params.size,
          total: res.data.count,
        };
        this.tableData = tableData;
      }
    },
    async checkContractInfo(params) {
      const res = await checkContractInfo(params);
      if (res && res.code === 0) {
        const { onLine = {}, ...rest } = res.data;
        const { invoice = {}, postInfo = {}, userInfo = {} } = onLine;
        const data = { ...rest, invoice, postInfo, userInfo };
        this.contractDetail = data;
      }
    },
    // 审核接口
    async contractAudit(params) {
      const res = await contractAudit(params);
      if (res && res.code === 0) {
        this.dialogAuditVisible = false;
        this.getTableList({
          page: this.tableData.page,
          size: this.tableData.size,
        }); // 成功后刷新列表
        this.$message.success("操作成功");
      }
    },
    // 其他
    init() {
      this.getTableList();
    },
  },
};
</script>
