import request from "@/config/serve.js";

// 查询
export function getContractList(params) {
  return request("post", "/apiGo/api/v1/app/manage/getContractList", params);
}
// 查询详情
export function checkContractInfo(params) {
  return request("post", "/apiGo/api/v1/app/manage/checkContractInfo", params);
}
//合同审核
export function contractAudit(data) {
  return request("post", "/apiGo/api/v1/app/manage/contractAuditExec", data);
}
//合同审核提交财务
export function addContractInfo(data) {
  return request("post", "/apiGo/api/v1/app/manage/addContractInfo", data);
}
